.yoyo{
    font-size: 2em;
}

.buttons button{
    font-size: 3em;
     
}

.rouge{
    color:coral;
}

.bleue{
    color:dodgerblue;
}

.rose{
    color:pink;
}


.tweetbox {
    background-color: rgb(212, 235, 255);
}

.length{
    font-size: 0.8em;
    color:dodgerblue;
}

.red{
    color:coral;
    
}
.red::after{
    content:'🧱';
}

nav{
    padding:30px 0;
}

.navrouge{
    background-color:coral;
}

 .navbleue{
    background-color:dodgerblue;
}

.navrose{
    background-color:pink;
}

.tweet {
    padding: 25px;
    color: darkslategrey;
    background: aliceblue;
    border: .5px dashed dodgerblue;
    width: 500px;
    margin: 3px auto;
}
.tweethead {
    float: left;
    border-right:1px solid blue;
    background: #add6ff;
    color: #302fa9;
    /* border: 1px solid; */
    font-weight: bold;
    padding: 4px;
    border-radius: 31px;
}
 .tweetusername{
    color:dodgerblue;
    font-style: italic;
}
 .tweetdate {
    display: inline-flex;
    margin: 3px;
    font-size: 0.75em;
    padding: 5px 0px;
    font-weight: 500;
}
.mainInscription{
    background-color:rgb(33, 34, 56);
    color:white; 
}

input{
    background-color: rgb(52, 56, 92);
    padding:5px;
    height: 40px;
    border-radius: 10px;
    color:white;
}

.wrap{
    background-color: rgb(58, 63, 97); 
    border: .5px solid rgb(93, 92, 145);
    border-radius: 25px;
    margin: 25px auto;
    padding: 10px;
}

form{
  max-width: 280px;
    margin: 0 auto;
}

.mainInscription  textarea{
    background-color: rgb(52, 56, 92);
    color:white;
    font-weight: bold;
    width: 250px;
    height:125px;
}

.tennis{
    background-color: rgb(190, 190, 190);
    font-size: 32px;
}


.score{
    background-color: rgb(85, 53, 231);
    width: 300px;
    display: block;
    margin: 0 auto;
    color:white;
    padding:20px;
}

.groupeBoutons {
    display: inline-flexbox;
    color: white;
    width: 442px;
    display: block;
    margin: 0 auto;
}

.pad{
    background-color: rgb(129, 129, 255);
    color:white;
    font-size: 18px;
    padding:20px;
    margin:20px;
    border:unset;
    border-radius: 3px;

}